html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
}

/* Basic styling for the dashboard */
.app {
  font-family: Arial, sans-serif;
}

.app-header {
  background-color: #282c34;
  padding: 20px;
  color: white;
  text-align: center;
}

.app-body {
  display: flex;
  height: calc(100vh - 60px);
  /* Subtract header height */
}

.app-sidebar {
  width: 200px;
  background-color: #f4f4f4;
  padding: 20px;
}

.app-sidebar ul {
  list-style: none;
  padding: 0;
}

.app-sidebar li {
  margin: 10px 0;
}

.app-sidebar a {
  text-decoration: none;
  color: #333;
}

.app-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

.app-content h2 {
  margin-top: 0;
}

.main-container {
  max-height: 100%;
}