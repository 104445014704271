.team-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
  }
  
  .team-member {
    /* background: #f9f9f9;
    border-radius: 8px; */
    padding: 20px;
    width: auto;
    text-align: center;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    transition: transform 0.3s;
  }
  
  .team-member:hover {
    transform: translateY(-10px);
  }
  
  .team-image {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 15px;
  }
  
  .team-role {
    font-weight: bold;
    color: #555;
  }
  
  .team-bio {
    color: #777;
    font-size: 0.9em;
  }
  
  .team-social a {
    text-decoration: none;
    color: #007bff;
    margin: 0 5px;
    transition: color 0.3s;
  }
  
  .team-social a:hover {
    color: #0056b3;
  }

  .team-member h2 {
    font-size: 14px;
  }