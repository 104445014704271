.legendclass {
    width: 72%;
    position : relative;
    left: 34px;
    height: 18px;
}

.occclass {
    position: relative;
}

.teamoccupancyclass {
    width: 100%;
}

.css-n3fyjk-MuiDataGrid-root {
    border: 0px !important;
}

.MuiDataGrid-columnHeaderTitle {
    font-weight: bold !important;
}

.MuiDataGrid-cellEmpty {
    width: 0px !important;
    display: none !important;
}

.MuiDataGrid-scrollbar {
    overflow: hidden !important;
}