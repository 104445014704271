.sidebar {
  width: 200px;
  box-shadow: 0 5px 6px 0 rgba(0, 0, 0, .2);
  background: #ffffff;
  height: 100%;
  text-wrap: nowrap;
}

.sidebar-active {
  display: block !important;
}

.sidebar h2 {
  text-align: center;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar ul li {
  cursor: pointer;
  font-weight: 400;
  /* border-bottom-style: inset; */
  padding: 10px;
  width: 180px;
  display: block;
  clear: both;
  border-bottom: 1px solid #e5e5e5;
  color: #000;
}

.sidebar ul li span,
.sidebar ul li div {
  color: #000;
  width: 100%;
  font: var(--ds-font-body, normal 400 14px / 20px ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Ubuntu, system-ui, "Helvetica Neue", sans-serif);
}

.sidebar ul li:hover {
  background-color: #e6effc;
}

.sidebar ul > .active {
  background-color: #e6effc;
}

.sidebar ul li a {
  display: flex;
  flex-direction: row;
  /* padding: 15px 20px 15px 15px; */
  border-left: 5px solid rgba(0, 0, 0, 0);
  text-decoration: none;
}

.sidebar .sidemenu-tab {
  font-size: 14px;
  margin-left: 20px;
  width: 80%;
}

.sidebar .sidemenu-arrow {
  position: relative;
  top: 7px;
}

.menu-item {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}