.css-bbfrx4 {
    padding: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    position: relative;
    border-radius: 20px;
    min-width: 0px;
    word-wrap: break-word;
    background: #ffffff;
    box-shadow: 14px 17px 40px 4px rgba(112, 144, 176, 0.2);
    -webkit-background-clip: border-box;
    background-clip: border-box;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-padding-start: 0px;
    padding-inline-start: 0px;
    -webkit-padding-end: 0px;
    padding-inline-end: 0px;
    font-family: 'Roboto, Helvetica, Arial, sans-serif';
    font-weight: 400;
}

.css-1dqbqs5 {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-padding-start: 25px;
    padding-inline-start: 25px;
    -webkit-padding-end: 25px;
    padding-inline-end: 25px;
    margin-bottom: 8px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.css-nmf4xv {
    color: #000;
    font-size: 18px;
    font-weight: 700;
    line-height: 100%;
}

.css-akw81x {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    margin-bottom: 20px;
}

.utilization-select-class {
    padding: 10px;
}

@media screen and (min-width: 768px) {
    .css-akw81x {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

@media screen and (min-width: 1200px) {
    .css-akw81x {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

/* .MuiDataGrid-cell {
    border: 0px !important;
} */

.MuiDataGrid-cellEmpty {
    width: 0px !important;
    display: none !important;
}