/* .container {
  background-color: grey;
  padding: 5px;
  display: flex;
  align-items: center;
} */

.label {
  margin-right: 10px;
}

.dropdown {
  /* padding: 10px; */
  font-size: 16px;
  border: 0.5px solid #ccc;
  border-radius: 5px;   
}

.button {
  margin-left: 20px;
}


/* .button a {
  color: inherit;
  text-decoration: none;
} */

/* .button a:hover {
  text-decoration: none;
} */

body{
  font-family: "Mukta", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.dd-list {
  font-family: "Mukta", sans-serif;
  font-weight: 300;
  font-style: normal;
}
.get-costing-link{
  font-style: normal;
  text-decoration: none;
  color: white;
}
.get-costing-button{
  background-color: #f27879;
  border: 1px solid black;
  color: black;
  padding: 0px;
  width: 90px;

}
.get-costing-button :hover{
  background-color: #bf1617;
}



.costing-dd{
  padding-top: 30px;
}

.costing-dd{
  display: flex;
  gap: 13px;
}
.buttons-all{
  margin-bottom: 20px;
}