.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #d52b1e;
    /* border-bottom: 2px solid #ddd; */
    box-shadow: 0 2px 10px 0 rgba(101, 110, 119, .25);
}

.logo-section {
    display: flex;
    align-items: center;
}

.logo {
    height: 50px;
}

.logo-text {
    font-size: 20px;
    font-weight: 520;
    color:rgb(255, 255, 255);
    font-family: Roboto, Helvetica, Arial, sans-serif;
}

.nav-links {
    display: flex;
    justify-content: center;
    flex-grow: 1;
    margin: 0 20px;
}

.nav-links a {
    text-decoration: none;
    color: #333;
    padding: 15px 20px;
    font-size: 1.3em; /* Increased font size */
    position: relative;
    margin: 0 10px; /* Equal gap between links */
}

.nav-links a:hover,
.nav-links a.active {
    color: #d9534f; /* Change this color as per your theme */
}

.nav-links a::after {
    content: '';
    display: block;
    width: 0;
    height: 2px;
    background: #d9534f; /* Change this color as per your theme */
    transition: width .3s;
}

.nav-links a:hover::after,
.nav-links a.active::after {
    width: 100%;
}


.register-button {
    display: flex;
    align-items: center;
}

.register-btn {
    background-color:#DF3418;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 5px;
}

.register-btn:hover {
    background-color: #DF3418;
}

.logo-divider {
    margin: 10px 10px 10px 2px;
    background-color: rgb(255, 255, 255);
    flex-shrink: 0;
    border-width: 0px thin 0px 0px;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    height: auto;
    align-self: stretch;
}

.menu-btn {
    display: inline-flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    position: relative;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    background-color: transparent;
    cursor: pointer;
    user-select: none;
    vertical-align: middle;
    appearance: none;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 0.8125rem;
    line-height: 1.75;
    letter-spacing: 0.02857em;
    text-transform: uppercase;
    color: #d52b1e;
    outline: 0px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
    margin: 0px;
    text-decoration: none;
    padding: 0px 8px 0 20px;
    border-radius: 4px;
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1), box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1), border-color 250ms cubic-bezier(0.4, 0, 0.2, 1), color 250ms cubic-bezier(0.4, 0, 0.2, 1);
    width: 50px;
    border: 0px;
}

.menu-btn-svg {
    color: rgb(255, 255, 255);
    padding-left: 0px;
    cursor: pointer;
}

.menu-btn-span {
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    z-index: 0;
    inset: 0px;
    border-radius: inherit;
}