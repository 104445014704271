.mb-1 {
    margin-bottom: .25rem !important;
}
.mt-2 {
    margin-top: .5rem !important;
}
.mb-3 {
    margin-bottom: 1rem !important;
}
.mb-4 {
    margin-bottom: 1.5rem !important;
}
.col-12 {
    flex: 0 0 auto;
    width: 100%;
}
.d-flex {
    display: flex !important;
}
.content {
    overflow: visible;
    padding: 0 1rem;
    transition: all .3s;
}
[class*=shadow] {
    transition: all .2s ease;
}
.row {
    --bs-gutter-x: 1.0rem;
    --bs-gutter-y: 0;
    display: flex;
    flex-wrap: wrap;
    margin-top: calc(var(--bs-gutter-y)* -1);
    margin-right: calc(var(--bs-gutter-x) / -2);
    margin-left: calc(var(--bs-gutter-x) / -2);
}

.row>* {
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
    padding-right: calc(var(--bs-gutter-x) / 2);
    padding-left: calc(var(--bs-gutter-x) / 2);
    margin-top: var(--bs-gutter-y);
}

.card {
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: .0625rem solid rgba(46, 54, 80, .125);
    border-radius: .5rem;
}

.border-light {
    border-color: #eaedf2 !important;
}
.shadow-sm {
    box-shadow: 0 2px 18px rgba(0, 0, 0, .02) !important;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem 1.5rem;
}

.align-items-center {
    align-items: center !important;
}

.align-text-center {
    text-align: center !important;
}

.icon {
    text-align: center;
    display: inline-flex;
    -moz-align-items: center;
    -ms-align-items: center;
    align-items: center;
    -moz-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    -ms-flex-pack: center;
}

.icon-shape {
    width: 4.5rem;
    height: 4.5rem;
    text-align: center;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.rounded {
    border-radius: .5rem !important;
}

.svg-inline--fa {
    display: inline-block;
    font-size: inherit;
    height: 1em;
    overflow: visible;
    vertical-align: -0.125em;
}

svg:not(:root).svg-inline--fa {
    overflow: visible;
}

.svg-inline--fa.fa-w-16 {
    width: 1em;
}

.icon-shape span, .icon-shape svg {
    font-size: 2rem;
}

.fa-chart-line:before {
    content: "\f201";
}

.card .h1, .card .h2, .card .h3, .card .h4, .card .h5, .card .h6, .card h1, .card h2, .card h3, .card h4, .card h5, .card h6 {
    font-family: "Nunito Sans", sans-serif;
    font-weight: 600;
}

.card .h5, .card h5 {
    font-size: 14px;
}

.card .h1, .card .h2, .card .h3, .card .h4, .card .h5, .card .h6, .card h1, .card h2, .card h3, .card h4, .card h5, .card h6 {
    margin-top: 0;
    margin-bottom: .5rem;
    line-height: 1.3;
    color: #262b40;
}

.card .small, .card small {
    font-size: .875em;
}

.text-success, .text-success:hover {
    color: #05a677 !important;
}

@media (min-width: 576px) {
    .me-sm-0 {
        margin-right: 0 !important;
    }
    .d-sm-none {
        display: none !important;
    }
    .d-sm-block {
        display: block !important;
    }
}

@media (min-width: 768px) {
    .justify-content-md-center {
        justify-content: center !important;
    }
}

@media (min-width: 1200px) {
    .px-xl-0 {
        padding-right: 0 !important;
        padding-left: 0 !important;
    }
    .col-xl-2 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-xl-3 {
        flex: 0 0 auto;
        width: 30%;
    }
    .col-xl-4 {
        flex: 0 0 auto;
        width: 20%;
    }
    .col-xl-5 {
        flex: 0 0 auto;
        width: 25%;
    }
    .col-xl-6 {
        flex: 0 0 auto;
        width: 48%;
    }
    .col-xl-7 {
        flex: 0 0 auto;
        width: 77.33333%;
    }
    .col-xl-8 {
        flex: 0 0 auto;
        width: 66.66667%;
    }
    .col-xl-12 {
        flex: 0 0 auto;
        width: 98%;
    }
    .d-xl-flex {
        display: flex !important;
    }
    .text-xl-center {
        text-align: center !important;
    }
    .mb-xl-0 {
        margin-bottom: 0 !important;
    }
    .justify-content-xl-center {
        justify-content: center !important;
    }
}

